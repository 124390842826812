import { Application, Assets, ParticleContainer } from 'pixi.js';
import Particle from './Particle';
export default class Particles {
    constructor(wrapper) {
        this.wrapper = wrapper;
        this.width = this.wrapper.clientWidth;
        this.height = this.wrapper.offsetHeight;
        this.particles = [];
        this.size = 2;
        this.mouse = {
            x: 0,
            y: 0,
        };
    }
    async mount() {
        this.app = new Application({
            width: this.width,
            height: this.height,
            autoDensity: true,
            backgroundAlpha: 0,
            resolution: devicePixelRatio,
        });
        globalThis.__PIXI_APP__ = this.app;
        this.wrapper.appendChild(this.app.view);
        this.canvasRect = this.wrapper.getBoundingClientRect();
        window.addEventListener('resize', () => {
            this.canvasRect = this.wrapper.getBoundingClientRect();
        });
        await this.addTexture();
        this.addContainer();
        this.addObjects();
    }
    async addTexture() {
        this.image = await Assets.load(`${require('../../assets/login-logo-next.png')}`);
    }
    addContainer() {
        this.container = new ParticleContainer(100000, {
            alpha: true,
        });
    }
    addObjects() {
        if (!this.container || !this.app) {
            return;
        }
        const canvas = document.createElement('canvas');
        this.wrapper.appendChild(canvas);
        const ctx = canvas.getContext('2d', { willReadFrequently: true });
        canvas.width = this.width * this.size;
        canvas.height = this.width * this.size;
        ctx.drawImage(this.image.baseTexture.resource.source, 0, 0);
        this.wrapper.removeChild(canvas);
        const entireImageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
        const data = entireImageData.data;
        const width = entireImageData.width;
        const hasFill = (x, y, size) => {
            for (let i = 0; i < size; i++) {
                for (let j = 0; j < size; j++) {
                    const index = ((y + j) * width + (x + i)) * 4;
                    if (data[index + 2] > 0) { // Check the blue channel value
                        return true;
                    }
                }
            }
            return false;
        };
        this.rows = this.image.baseTexture.width / this.size;
        this.cols = this.image.baseTexture.height / this.size;
        for (let i = 0; i < this.rows; i += 1) {
            for (let j = 0; j < this.cols; j += 1) {
                if (hasFill(i * this.size, j * this.size, this.size)) {
                    const p = new Particle(i * this.size, j * this.size, this.image, this.size);
                    this.particles.push(p);
                    this.container.addChild(p.sprite);
                }
            }
        }
        this.app.stage.addChild(this.container);
        this.reveal();
        this.animate();
    }
    startAnimation() {
        this.stopAnimationTimeot && clearTimeout(this.stopAnimationTimeot);
        if (this.app)
            this.app.ticker.start();
    }
    stopAnimation() {
        this.stopAnimationTimeot = setTimeout(() => {
            if (this.app)
                this.app.ticker.stop();
        }, 3000);
    }
    reveal() {
        this.particles.forEach(p => {
            p.reveal();
        });
        setTimeout(() => {
            this.particles.forEach(p => {
                p.resetGravity();
            });
        }, 1000);
    }
    animate() {
        this.app.ticker.add(() => {
            const mouse = this.app.renderer.events.pointer;
            this.mouse = {
                x: mouse.x - this.canvasRect.x,
                y: mouse.y - this.canvasRect.y,
            };
            this.particles.forEach(p => {
                p.update(this.mouse);
            });
        });
    }
    unmount() {
        this.app.destroy();
        this.app = undefined;
    }
}
