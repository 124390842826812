import { Sprite, Texture, Rectangle } from 'pixi.js';
export default class Particle {
    constructor(x, y, texture, size) {
        this.x = x + 100;
        this.y = y + 100;
        this.sprite = new Sprite(new Texture(texture));
        this.sprite.texture.frame = new Rectangle(x, y, size, size);
        this.sprite.x = x + 150;
        this.sprite.y = y + 150;
        this.friction = 0.9;
        this.gravity = 0.005 + Math.random() * 0.005;
        this.maxGravity = 0.003 + Math.random() * 0.003;
        this.speedX = 0;
        this.speedY = 0;
        this.pulseIntense = {
            value: 0,
        };
        this.radius = 50;
    }
    reveal() {
        this.sprite.alpha = 0;
        const distanceX = 450 - this.sprite.x;
        const distanceY = 450 - this.sprite.y;
        const distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);
        const normalizeX = distanceX / distance;
        const normalizeY = distanceY / distance;
        this.sprite.x -= normalizeX * 400;
        this.sprite.y -= normalizeY * 400;
    }
    resetGravity() {
        this.maxGravity = 0.01 + Math.random() * 0.07;
    }
    update({ x: mouseX, y: mouseY }) {
        const distanceX = mouseX - this.sprite.x;
        const distanceY = mouseY - this.sprite.y;
        const distancePulseX = 450 - this.sprite.x;
        const distancePulseY = 450 - this.sprite.y;
        const distancePulse = Math.sqrt(distancePulseX ** 2 + distancePulseY ** 2);
        const normalizePulseX = distancePulseX / distancePulse;
        const normalizePulseY = distancePulseY / distancePulse;
        const distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);
        const normalizeX = distanceX / distance;
        const normalizeY = distanceY / distance;
        if (this.sprite.alpha < 0.3) {
            this.sprite.alpha = this.sprite.alpha + 0.005;
        }
        else if (this.sprite.alpha < 0.5) {
            this.sprite.alpha = this.sprite.alpha + 0.01;
        }
        else if (this.sprite.alpha < 0.7) {
            this.sprite.alpha = this.sprite.alpha + 0.02;
        }
        else if (this.sprite.alpha < 1) {
            this.sprite.alpha = this.sprite.alpha + 0.05;
        }
        // mouse movement
        if (distance < this.radius) {
            this.gravity *= this.friction;
            this.speedX -= normalizeX;
            this.speedY -= normalizeY;
        }
        else {
            this.gravity += 0.1 * (this.maxGravity - this.gravity);
        }
        this.speedX -= normalizePulseX * this.pulseIntense.value;
        this.speedY -= normalizePulseY * this.pulseIntense.value;
        // return back
        const originalDistX = this.x - this.sprite.x;
        const originalDistY = this.y - this.sprite.y;
        this.speedX += originalDistX * this.gravity;
        this.speedY += originalDistY * this.gravity;
        this.speedX *= this.friction;
        this.speedY *= this.friction;
        this.sprite.x += this.speedX;
        this.sprite.y += this.speedY;
    }
}
