import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, createSlots as _createSlots, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue";
const _hoisted_1 = { class: "layout-row items-center flex-grow justify-center md:justify-between max-w-[1044px]" };
const _hoisted_2 = {
    key: 0,
    class: "flex h-[504px] w-[492px] justify-center items-center"
};
const _hoisted_3 = {
    key: 1,
    class: "px-md absolute left-0 right-0 top-sm"
};
const _hoisted_4 = {
    key: 2,
    class: "form layout-column p-md sm:p-0 sm:w-[343px] md:w-[352px]"
};
const _hoisted_5 = { class: "typography-display-small mb-md text-center md:text-left" };
const _hoisted_6 = { class: "typography-body-2-regular mb-xl text-center md:text-left" };
const _hoisted_7 = { class: "typography-body-2-bold" };
const _hoisted_8 = { class: "layout-row gap-md items-start" };
const _hoisted_9 = {
    ref: "captchaV2Checkbox",
    class: "flex justify-center mb-md+"
};
const _hoisted_10 = {
    key: 3,
    class: "form layout-column p-md sm:p-0 gap-xl w-full sm:w-[343px] md:w-[352px]"
};
const _hoisted_11 = { class: "layout-column gap-md items-center md:items-stretch" };
const _hoisted_12 = { class: "typography-display-small dark:text-on-background-dark" };
const _hoisted_13 = { class: "layout-column gap-md" };
const _hoisted_14 = { class: "layout-row justify-between gap-md" };
const _hoisted_15 = {
    ref: "captchaV2Checkbox",
    class: "flex justify-center mb-md+"
};
const _hoisted_16 = { class: "layout-column gap-md" };
const _hoisted_17 = {
    key: 1,
    class: "w-full layout-column gap-md"
};
const _hoisted_18 = { class: "layout-column gap-md" };
const _hoisted_19 = { class: "layout-row gap-md justify-between md:justify-end" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_OrAnimationLogo = _resolveComponent("OrAnimationLogo");
    const _component_OrNotification = _resolveComponent("OrNotification");
    const _component_OrLogo = _resolveComponent("OrLogo");
    const _component_OrIconButton = _resolveComponent("OrIconButton");
    const _component_OrInput = _resolveComponent("OrInput");
    const _component_OrButton = _resolveComponent("OrButton");
    const _component_OrSubtitles = _resolveComponent("OrSubtitles");
    const _component_OrCheckbox = _resolveComponent("OrCheckbox");
    const _component_GoogleLogin = _resolveComponent("GoogleLogin");
    const _component_OrModal = _resolveComponent("OrModal");
    const _component_PasswordRecovery = _resolveComponent("PasswordRecovery");
    const _component_LoginContainer = _resolveComponent("LoginContainer");
    return (_openBlock(), _createBlock(_component_LoginContainer, null, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                (_ctx.isDesktop)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_component_OrAnimationLogo)
                    ]))
                    : _createCommentVNode("", true),
                (_ctx.isExpired)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_OrNotification, {
                            class: "sm:max-w-[768px] mx-auto",
                            onClose: _ctx.closeExpiredNotification
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate('login.login.Due to inactivity, your session has expired')), 1)
                            ]),
                            _: 1
                        }, 8, ["onClose"])
                    ]))
                    : _createCommentVNode("", true),
                (_ctx.twoFactorCheck)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_component_OrLogo, { class: "self-center md:hidden mb-md" }),
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.translate('login.login.twofactorVerification')), 1),
                        _createElementVNode("span", _hoisted_6, [
                            _createTextVNode(_toDisplayString(_ctx.translate('login.login.Your profile')) + " ", 1),
                            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.credentials.username), 1),
                            _createTextVNode(" " + _toDisplayString(_ctx.translate('login.login.has been configured to use two-factor authentication')), 1)
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_OrInput, {
                                ref: "twoFactorInput",
                                modelValue: _ctx.verificationCode,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.verificationCode) = $event)),
                                class: "verification-code flex-auto mb-xl",
                                placeholder: _ctx.translate('login.login.Enter Verification Code'),
                                loading: _ctx.authenticating,
                                label: _ctx.translate('login.login.verificationCode'),
                                error: _ctx.serviceError,
                                inputmode: "decimal",
                                autocomplete: "one-time-code",
                                onKeyup: _withKeys(_ctx.submit, ["enter", "native"])
                            }, _createSlots({ _: 2 }, [
                                (!_ctx.authenticating)
                                    ? {
                                        name: "appendInner",
                                        fn: _withCtx(() => [
                                            _createVNode(_component_OrIconButton, {
                                                tooltip: { content: _ctx.$t('login.login.Paste from clipboard'), placement: 'top' },
                                                size: "l",
                                                icon: "content_paste",
                                                onClick: _withModifiers(_ctx.pasteFromBuffer, ["stop"])
                                            }, null, 8, ["tooltip", "onClick"])
                                        ]),
                                        key: "0"
                                    }
                                    : undefined
                            ]), 1032, ["modelValue", "placeholder", "loading", "label", "error", "onKeyup"])
                        ]),
                        (_ctx.isDesktop)
                            ? (_openBlock(), _createBlock(_component_OrButton, {
                                key: 0,
                                class: "sign-in md:self-start mb-xl",
                                variant: "link",
                                onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.toogleOpenRecovery2fa(true)))
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate('login.login.havingProblem')), 1)
                                ]),
                                _: 1
                            }))
                            : _createCommentVNode("", true),
                        _withDirectives(_createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_9, null, 512)
                        ], 512), [
                            [_vShow, _ctx.showCaptchaV2Checkbox]
                        ]),
                        (!_ctx.isDesktop)
                            ? (_openBlock(), _createBlock(_component_OrButton, {
                                key: 1,
                                class: "sign-in !p-0 mb-xl",
                                variant: "text",
                                onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.toogleOpenRecovery2fa(true)))
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate('login.login.havingProblem')), 1)
                                ]),
                                _: 1
                            }))
                            : _createCommentVNode("", true),
                        _createVNode(_component_OrButton, {
                            class: "md:self-start",
                            variant: "link",
                            onClick: _ctx.logout
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate('login.login.Use different email')), 1)
                            ]),
                            _: 1
                        }, 8, ["onClick"])
                    ]))
                    : _createCommentVNode("", true),
                (!_ctx.twoFactorCheck)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createVNode(_component_OrLogo, { class: "self-center md:hidden mb-md" }),
                        _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("h2", _hoisted_12, _toDisplayString(_ctx.translate('login.login.signInToOnereachai')), 1),
                            _createVNode(_component_OrSubtitles)
                        ]),
                        _createElementVNode("div", _hoisted_13, [
                            (_ctx.serviceError)
                                ? (_openBlock(), _createBlock(_component_OrNotification, {
                                    key: 0,
                                    class: "error",
                                    variant: "error"
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.serviceError), 1)
                                    ]),
                                    _: 1
                                }))
                                : _createCommentVNode("", true),
                            _createVNode(_component_OrInput, {
                                modelValue: _ctx.credentials.username,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.credentials.username) = $event)),
                                modelModifiers: { trim: true },
                                "data-test": "username",
                                class: "username",
                                name: "username",
                                type: "text",
                                error: _ctx.emailError || !!_ctx.serviceError,
                                label: _ctx.translate('login.login.emailAddress'),
                                placeholder: _ctx.translate('login.login.Enter email'),
                                disabled: _ctx.authenticating,
                                onKeyup: _withKeys(_ctx.submit, ["enter", "native"])
                            }, null, 8, ["modelValue", "error", "label", "placeholder", "disabled", "onKeyup"]),
                            _createVNode(_component_OrInput, {
                                modelValue: _ctx.credentials.password,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.credentials.password) = $event)),
                                class: "password",
                                "data-test": "password",
                                type: "password",
                                error: _ctx.passwordError || !!_ctx.serviceError,
                                label: _ctx.translate('login.login.password'),
                                disabled: _ctx.authenticating,
                                placeholder: _ctx.translate('login.login.Enter password'),
                                onKeyup: _withKeys(_ctx.submit, ["enter", "native"])
                            }, null, 8, ["modelValue", "error", "label", "disabled", "placeholder", "onKeyup"]),
                            _createElementVNode("div", _hoisted_14, [
                                _createVNode(_component_OrCheckbox, {
                                    modelValue: _ctx.keepLoggedIn,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((_ctx.keepLoggedIn) = $event)),
                                    "data-test": "keepLoggedIn",
                                    class: "form-checkbox grey",
                                    disabled: _ctx.authenticating,
                                    onKeyup: _withKeys(_ctx.submit, ["enter", "native"])
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate('login.login.Keep me signed in')), 1)
                                    ]),
                                    _: 1
                                }, 8, ["modelValue", "disabled", "onKeyup"]),
                                _createVNode(_component_OrButton, {
                                    variant: "text",
                                    class: "!p-0",
                                    onClick: _withModifiers(_ctx.showForgotPopup, ["prevent"])
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate('login.login.forgotPassword')), 1)
                                    ]),
                                    _: 1
                                }, 8, ["onClick"])
                            ])
                        ]),
                        _withDirectives(_createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_15, null, 512)
                        ], 512), [
                            [_vShow, _ctx.showCaptchaV2Checkbox]
                        ]),
                        _createElementVNode("div", _hoisted_16, [
                            _createVNode(_component_OrButton, {
                                "data-test": "submit",
                                loading: _ctx.authenticating,
                                class: "sign-in",
                                type: "submit",
                                onClick: _ctx.submit
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.twoFactorCheck ? _ctx.translate('login.login.verify') : _ctx.translate('login.login.signIn')), 1)
                                ]),
                                _: 1
                            }, 8, ["loading", "onClick"]),
                            (_ctx.GOOGLE_LOGIN)
                                ? (_openBlock(), _createBlock(_component_GoogleLogin, {
                                    key: 0,
                                    loading: _ctx.googleSSOLoading,
                                    "button-type": "outlined",
                                    label: _ctx.translate('login.login.Sign in with Google')
                                }, null, 8, ["loading", "label"]))
                                : _createCommentVNode("", true),
                            (_ctx.externalProviders.length > 0)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.externalProviders, (provider) => {
                                        return (_openBlock(), _createBlock(_component_OrButton, {
                                            key: provider.name,
                                            loading: _ctx.authenticating,
                                            variant: "outlined",
                                            onClick: ($event) => (_ctx.initiateExternalLogin(provider.name, _ctx.redirectPath))
                                        }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.translate('login.login.Sign in with', { providerName: provider.name })), 1)
                                            ]),
                                            _: 2
                                        }, 1032, ["loading", "onClick"]));
                                    }), 128))
                                ]))
                                : _createCommentVNode("", true)
                        ])
                    ]))
                    : _createCommentVNode("", true)
            ]),
            _createVNode(_component_OrModal, {
                size: "s",
                "is-open": _ctx.isOpenRecovery2fa,
                class: "recovery-modal",
                onClose: _cache[8] || (_cache[8] = ($event) => (_ctx.toogleOpenRecovery2fa(false)))
            }, {
                header: _withCtx(() => [
                    _createElementVNode("div", null, _toDisplayString(_ctx.translate('login.login.accessRecovery')), 1)
                ]),
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.translate('login.login.ifYouHaveProblems')), 1),
                        _createVNode(_component_OrInput, {
                            modelValue: _ctx.recoveryCode,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((_ctx.recoveryCode) = $event)),
                            modelModifiers: { trim: true },
                            error: _ctx.recoveryError,
                            label: _ctx.translate('login.login.recoveryCode'),
                            onKeyup: _withKeys(_ctx.submit, ["enter", "native"])
                        }, null, 8, ["modelValue", "error", "label", "onKeyup"]),
                        _createElementVNode("div", null, _toDisplayString(_ctx.translate('login.login.ifYouveLost')), 1)
                    ])
                ]),
                footer: _withCtx(() => [
                    _createElementVNode("div", _hoisted_19, [
                        _createVNode(_component_OrButton, {
                            variant: "outlined",
                            class: "flex-1 md:!flex-none",
                            onClick: _cache[7] || (_cache[7] = _withModifiers(($event) => (_ctx.toogleOpenRecovery2fa(false)), ["prevent"]))
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate('login.login.cancel')), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_OrButton, {
                            color: "primary",
                            class: "flex-1 md:!flex-none",
                            loading: _ctx.authenticating,
                            onClick: _withModifiers(_ctx.submit, ["prevent"])
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate('login.login.submit')), 1)
                            ]),
                            _: 1
                        }, 8, ["loading", "onClick"])
                    ])
                ]),
                _: 1
            }, 8, ["is-open"]),
            _createVNode(_component_PasswordRecovery, {
                ref: "resetPasswordModal",
                "redirect-path": _ctx.redirectPath
            }, null, 8, ["redirect-path"])
        ]),
        _: 1
    }));
}
