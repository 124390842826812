import '@onereach/styles/main-v3.css';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import App from './app.vue';
import i18n from './i18n/i18n';
import router from './router/router';
import SentryApi from './sentry';
import store from './vuex/store';
const app = createApp(App);
const pinia = createPinia();
app.use(router);
app.use(store);
app.use(i18n);
app.use(pinia);
SentryApi.init(app, router);
app.mount('#app');
