import _ from 'lodash';
import { createRouter, createWebHistory } from 'vue-router';
import { checkIfIframe } from '@/utils/checkIfIframe';
import helpers from '../helpers/index';
import store from '../vuex/store';
import routes from './routes';
import { IframeStatusMessages } from './utils/iframe-routing/constants';
import { processIframeEvents, sendIframeResponseEvent, validateIframeActions, waitForIframeEvent, } from './utils/iframe-routing/methods';
const flatRoutes = _.flatMap(routes, route => {
    var _a;
    if (_.isEmpty(route.children)) {
        return route;
    }
    return [route].concat(((_a = route.children) === null || _a === void 0 ? void 0 : _a.map(child => {
        var _a;
        return ({
            ...child,
            meta: {
                auth: (_a = route.meta) === null || _a === void 0 ? void 0 : _a.auth,
            },
        });
    })) || []);
});
const router = createRouter({
    history: createWebHistory(),
    routes,
});
let eventTriggered = false;
router.beforeEach(async (to) => {
    var _a, _b, _c, _d, _e, _f;
    const route = flatRoutes.find(r => r.name === to.name);
    if (!route) {
        return;
    }
    // iframe logic
    if (checkIfIframe() && ((_a = to.meta) === null || _a === void 0 ? void 0 : _a.iframeEventRequired)) {
        if (!eventTriggered) {
            const eventData = await waitForIframeEvent();
            eventTriggered = true;
            if (validateIframeActions(eventData.action)) {
                return processIframeEvents(eventData.action);
            }
            else {
                sendIframeResponseEvent(IframeStatusMessages.InvalidAction);
            }
        }
        eventTriggered = false;
    }
    let redirectPathQuery = _.get(to, 'query.redirectPath', '');
    redirectPathQuery = helpers.removeURLParameter(redirectPathQuery, 'token');
    if (redirectPathQuery) {
        await store.dispatch('auth/setRedirectUrl', redirectPathQuery);
    }
    if ((_b = to.meta) === null || _b === void 0 ? void 0 : _b.authMult) {
        await store.dispatch('auth/validateMultiUser').catch(() => { });
        sessionStorage.setItem('redirect-mult', to.fullPath);
        // Crutch for single-user tests
        if (to.name !== 'profile-activity') {
            if (!store.state.auth.multiUser)
                return '/logout';
        }
    }
    if (_.includes(['logout', 'validate-payment'], route.name) || /multi-user/.test((typeof route.name == 'string' && route.name) || '')) {
        return true;
    }
    if (!((_c = to.meta) === null || _c === void 0 ? void 0 : _c.allowSuspended) && store.state.auth.isSuspended) {
        return '/account/payments';
    }
    if (_.isNil(store.state.auth.authorized) && to.name !== 'auth-provider') {
        const initialPath = to.path;
        try {
            await store.dispatch('auth/init', {
                initialPath,
                clearCookies: !((_d = to.meta) === null || _d === void 0 ? void 0 : _d.authMult),
                redirectPath: redirectPathQuery,
            });
            if (initialPath === '/account/payments') {
                try {
                    await store.dispatch('auth/getAccountIsPayable');
                    return store.state.auth.isPayable ? true : '/account';
                }
                catch (e) {
                    return '/account';
                }
            }
            store.dispatch('auth/getAccountIsPayable');
            if (!((_e = to.meta) === null || _e === void 0 ? void 0 : _e.allowSuspended) && store.state.auth.isSuspended) {
                return '/account/payments';
            }
            return true;
        }
        catch (e) {
            if (route.path === '*' || ((_f = route.meta) === null || _f === void 0 ? void 0 : _f.auth)) {
                return '/logout';
            }
            else {
                return true;
            }
        }
    }
    return true; // eslint-disable-line consistent-return
});
router.afterEach((to) => {
    var _a;
    if ((_a = to.meta) === null || _a === void 0 ? void 0 : _a.title) {
        document.title = to.meta.title;
    }
});
export default router;
