import _ from 'lodash';
export var ErrorActions;
(function (ErrorActions) {
    ErrorActions[ErrorActions["RenderCaptcha"] = 0] = "RenderCaptcha";
    ErrorActions[ErrorActions["Expired"] = 1] = "Expired";
})(ErrorActions || (ErrorActions = {}));
export const getFormatedError = (value, translate) => {
    const message = _.get(value, 'message', '');
    const result = {
        message: 'Error. Please try again.',
    };
    if (value === 'Unauthorized') {
        result.message = '',
            result.action = ErrorActions.Expired;
    }
    else if (value === 'Invalid verification code') {
        result.message = 'Invalid verification code';
    }
    else if (!navigator.onLine) {
        result.message = 'Network Error. Please check your connection and try again.';
    }
    else if (_.includes(['Invalid username and/or password', 'Wrong credentials'], message)) {
        result.message = translate('login.login.The email and password combination is not valid');
    }
    else if (_.isEqual(message, 'Access suspended')) {
        result.message = 'Account access is suspended. Please contact OneReach.ai support.';
    }
    else if (_.isEqual(message, 'Two Factor token invalid')) {
        result.message = 'Verification code id invalid or expired. Please try again.';
    }
    else if (_.includes(message, 'account is missing or disabled') ||
        _.includes(message, 'Account is disabled')) {
        result.message = translate('multi_user.list_users.accountDisabled');
    }
    else if (_.includes(message, 'Incorrect Captcha: low score')) {
        result.message = '';
        result.action = ErrorActions.RenderCaptcha;
    }
    else if (_.includes(message, 'Incorrect Captcha')) {
        result.message = 'Error verifying reCAPTCHA. Please try again.';
    }
    else if (_.includes(message, 'You are allowed')) {
        result.message = message;
    }
    else if (message === 'You have exceeded the request limit') {
        result.message = translate('multi_user.list_users.Too many requests');
    }
    return result;
};
