import { createStore } from 'vuex';
import createLogger from './plugins/logger';
import config from '../config';
import auth from './modules/auth';
const silent = config.SILENT;
const storeLogs = config.STORE_LOGS;
export default createStore({
    modules: {
        auth,
    },
    plugins: storeLogs ? [createLogger()] : [],
    strict: !silent,
});
