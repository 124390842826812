export const AUTH_INIT = 'AUTH_INIT';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_SUSPENDED = 'AUTH_SUSPENDED';
export const SET_ALLOW_GUEST = 'SET_ALLOW_GUEST';
export const SIGN_OUT = 'SIGN_OUT';
export const TWO_FACTOR_CHECK = 'TWO_FACTOR_CHECK';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';
export const AUTH_COOKIES_EXPIRED = 'AUTH_COOKIES_EXPIRED';
export const AUTH_ANOTHER_USER = 'AUTH_ANOTHER_USER';
export const AUTH_NO_RIGHTS = 'AUTH_NO_RIGHTS';
export const GENERATE_FINGERPRINT = 'GENERATE_FINGERPRINT';
export const START_LOADING_VERIFICATION_CODES = 'START_LOADING_VERIFICATION_CODES';
export const SET_VERIFICATION_CODES = 'SET_VERIFICATION_CODES';
export const MULTI_USER_IS_LOGGIN_USER = 'MULTI_USER_IS_LOGGIN_USER';
export const AUTH_SET_MULTI_USER = 'AUTH_SET_MULTI_USER';
export const MULTI_USER_TOGGLE_MFA = 'MULTI_USER_TOGGLE_MFA';
export const MULTI_USER_AVAILABLE_USERS = 'MULTI_USER_AVAILABLE_USERS';
export const SSO_NEEDS_TWO_FACTOR = 'SSO_NEEDS_TWO_FACTOR';
export const SET_IS_SSO = 'SET_IS_SSO';
export const SET_IS_PAYABLE = 'SET_IS_PAYBLE';
