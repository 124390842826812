import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';
import { useAuth } from '@/composables/useAuth';
export default defineComponent({
    setup() {
        const { getProviderByName } = useAuth();
        return { getProviderByName };
    },
    computed: {
        ...mapState({
            //@ts-ignore
            authorized: ({ auth }) => auth.authorized,
            identityProvider: ({ auth }) => auth.identityProvider,
            error: ({ auth }) => auth.error,
            token: ({ auth }) => auth.token,
        }),
    },
    async created() {
        try {
            await this.init({
                initialPath: '/',
                allowGuest: true,
            });
            await this.signOut(this.token);
            if (this.identityProvider) {
                const provider = this.getProviderByName(this.identityProvider);
                if (provider && provider.logoutUrl) {
                    //@ts-ignore
                    window.location = provider.logoutUrl;
                    return;
                }
            }
            this.$router.replace({ name: 'login' });
        }
        catch (error) {
            await this.signOut(this.token);
            this.$router.replace({ name: 'login' });
        }
    },
    methods: {
        ...mapActions('auth', [
            'signOut',
            'init',
        ]),
    },
});
