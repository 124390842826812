import { useElementHover, useEventListener } from '@vueuse/core';
import { onMounted, onBeforeUnmount, ref, defineComponent, watch } from 'vue-demi';
import Particles from '../../utils/Animations/Particles';
export default defineComponent({
    setup() {
        let animation = null;
        const animationWrapper = ref();
        const isHovered = useElementHover(animationWrapper);
        onMounted(async () => {
            animation = new Particles(animationWrapper.value);
            await animation.mount();
            animation === null || animation === void 0 ? void 0 : animation.stopAnimation();
        });
        const animateOnTabFocus = () => {
            animation === null || animation === void 0 ? void 0 : animation.startAnimation();
            setTimeout(() => {
                if (!isHovered.value) {
                    animation === null || animation === void 0 ? void 0 : animation.stopAnimation();
                }
            }, 3000);
        };
        watch(isHovered, (isHovered) => {
            if (isHovered) {
                animation === null || animation === void 0 ? void 0 : animation.startAnimation();
            }
            else {
                animation === null || animation === void 0 ? void 0 : animation.stopAnimation();
            }
        });
        onMounted(() => {
            useEventListener(window, 'focus', animateOnTabFocus);
        });
        onBeforeUnmount(() => {
            animation === null || animation === void 0 ? void 0 : animation.unmount();
        });
        return {
            animationWrapper,
        };
    },
});
